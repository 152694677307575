a {
  color: unset;
}
a:hover {
  color: unset;
  text-decoration: none;
}
body {
  background-color: #f4abff !important;
  font-family: 'Titillium Web', sans-serif !important;
}
